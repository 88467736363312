var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.readonly ? _c('v-text-field', _vm._b({
    attrs: {
      "readonly": ""
    },
    model: {
      value: _vm.formattedDate,
      callback: function callback($$v) {
        _vm.formattedDate = $$v;
      },
      expression: "formattedDate"
    }
  }, 'v-text-field', _vm.$attrs, false)) : _c('v-menu', {
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "max-width": "290px",
      "min-width": "290px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "prepend-icon": "mdi-calendar",
            "readonly": "",
            "data-cy": "".concat(_vm.dataCy, "Input")
          },
          on: {
            "click:clear": _vm.clearDate
          },
          model: {
            value: _vm.formattedDate,
            callback: function callback($$v) {
              _vm.formattedDate = $$v;
            },
            expression: "formattedDate"
          }
        }, 'v-text-field', Object.assign({}, attrs, _vm.$attrs), false), on))];
      }
    }]),
    model: {
      value: _vm.menu,
      callback: function callback($$v) {
        _vm.menu = $$v;
      },
      expression: "menu"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "data-cy": "".concat(_vm.dataCy, "Popup"),
      "no-title": "",
      "min": _vm.minDate,
      "max": _vm.maxDate,
      "first-day-of-week": 1
    },
    on: {
      "input": function input($event) {
        _vm.menu = false;
      }
    },
    model: {
      value: _vm.date,
      callback: function callback($$v) {
        _vm.date = $$v;
      },
      expression: "date"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }