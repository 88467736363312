
import { getIsoDate } from "@/shared/helpers/dateHelpers";
import { computed, defineComponent, PropType, ref, watch } from "@vue/composition-api";
import { endOfDay, format } from "date-fns";
import { nb } from "date-fns/locale";

export default defineComponent({
  name: "BaseDatePicker",
  props: {
    value: {
      type: [String, Date] as PropType<string | Date>,
      required: false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    format: {
      type: String,
      default: "PP",
    },
    min: {
      type: String,
      default: null,
    },
    max: {
      type: String,
      default: null,
    },
    dataCy: {
      type: String,
      required: false,
    },
    forceEndOfDate: {
      type: Boolean,
      default: false,
    },
    emitAsDate: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["input"],
  setup(props, { emit }) {
    const menu = ref(false);
    const date = ref(props.value ? props.value : "");
    const formattedDate = ref(props.value ? formatDate(props.value, props.format) : "");

    // v-date-picker model only accepts date as string (ISO 8601 DATE) on this format: YYYY-MM-DD
    const getDateFromValue = (value?: string | Date) => {
      if (value instanceof Date) {
        value = getIsoDate(value);
      } else if (typeof value === "object" || typeof value === "string") {
        value = getIsoDate(value);
      } else {
        console.warn(value);
        throw new Error("Invalid date");
      }
      return value;
    };

    watch(
      () => props.value,
      (newValue) => {
        if (newValue != date.value && newValue !== undefined) {
          date.value = getDateFromValue(newValue);
        }
      },
      { immediate: true }
    );

    watch(date, (value) => {
      if (!value) {
        return emit("input", "");
      }

      // Set the formatted date that is shown in the text field
      formattedDate.value = format(new Date(date.value), props.format, { locale: nb });

      props.emitAsDate;

      // force start of day on else? TODO
      const emitValue = props.forceEndOfDate ? endOfDay(new Date(value)) : new Date(value);

      if (props.emitAsDate) {
        emit("input", emitValue);
      } else {
        emit("input", emitValue.toISOString());
      }
    });

    return {
      menu,
      date,
      formattedDate,
      minDate: computed(() => (props.min ? getIsoDate(props.min) : undefined)),
      maxDate: computed(() => (props.max ? getIsoDate(props.max) : undefined)),
      clearDate: () => (date.value = ""),
    };
  },
});

const formatDate = (date: string | Date, dateFormat: string) => format(new Date(date), dateFormat, { locale: nb });
